nav {
  box-shadow: 0 1em 1em -1em rgba(0, 0, 0, 0.25);
}

.navbar-logo {
  max-width: 25%;
}

.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: #040182 !important;
  background: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

button.navbar-toggler {
  background-color: #1e1bc5;
}

button.navbar-toggler span {
  color: white;
}

button.navMessageButton {
  background: inherit;
  border: #040182;
  padding-top: 6.4%;
}

button.navMessageButton:hover {
  background: #fff;
  color: #040182;
}

@media only screen and (max-width: 450px) {
  button.navbar-toggler {
    margin-left: 85%;
    margin-top: -12%;
  }

  button.navMessageButton {
    padding-top: 2%;
    margin-left: -4%;
  }
}
